<template>
	<div class="data-content">
		<div class="top-box">
			<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('train:offline:add')">新增</button>
			<!-- <button class="import-btn" @click="handleImport" v-if="perms.includes('train:offline:import')">导入</button>
			<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)"> -->
			<button class="import-btn" @click="handleExport" v-if="perms.includes('train:offline:export')">导出</button>
			<!-- <button class="template-btn" @click="handleTemplate" v-if="perms.includes('train:offline:import')">模板下载</button> -->
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">状态</label>
				<el-select class="search-input" size="small" v-model="publishFlag" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="已发布" value="1"></el-option>
					<el-option label="待发布" value="0"></el-option>
					<el-option label="关闭" value="3"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="getDataList">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				border
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="eduPlanCode"
					width="110"
					show-overflow-tooltip
					label="教育计划编号">
				</el-table-column>
				<el-table-column
					prop="rangeName"
					show-overflow-tooltip
					label="地区">
				</el-table-column>
				<el-table-column
					prop="roomName"
					show-overflow-tooltip
					label="会场名称">
				</el-table-column>
				<el-table-column
					prop="trainName"
					show-overflow-tooltip
					label="培训地点">
				</el-table-column>
				<el-table-column
					prop="courseCode"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="courseName"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="areaName"
					show-overflow-tooltip
					label="培训期间">
					<template slot-scope="scope">
						{{scope.row.startTime.replace(new RegExp('-','g'),'/')}}~{{scope.row.endTime.replace(new RegExp('-','g'),'/')}}
					</template>
				</el-table-column>
				<el-table-column
					prop="teachName"
					show-overflow-tooltip
					label="教师">
				</el-table-column>
				<el-table-column
					prop="enterMax"
					show-overflow-tooltip
					label="报名上限">
				</el-table-column>
				<el-table-column
					prop="enterMin"
					show-overflow-tooltip
					label="报名下限">
				</el-table-column>
				<el-table-column
					prop="enterStopTime"
					width="110"
					show-overflow-tooltip
					label="报名截止时间">
					<template slot-scope="scope">
						<span>{{scope.row.enterStopTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					label="状态"
					align="center"
					width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.publishFlag == 1">已发布</span>
						<span v-if="scope.row.publishFlag == 0 || scope.row.publishFlag == 2">待发布</span>
						<span v-if="scope.row.publishFlag == 3">关闭</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">详细</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:offline:update') && [0,2].includes(scope.row.publishFlag)">编辑</button>
						<button class="btn-blue" @click="handleEditDataStatus(scope.row)" v-if="perms.includes('train:offline:publish') && [0,2].includes(scope.row.publishFlag)">发布</button>
						<button class="btn-red" @click="handleEditDataStatus(scope.row)" v-if="perms.includes('train:offline:revoke') && scope.row.publishFlag == 1">撤销</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:offline:delete') && [0,2].includes(scope.row.publishFlag)">删除</button>
						<button class="btn-red" @click="handleCloseData(scope.row)" v-if="perms.includes('train:offline:close') && [0,2].includes(scope.row.publishFlag)">关闭</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getDataList="getDataList"></edit-data>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			pageNo: 1,
			pageSize: 20,
			total: 0,
			publishFlag: '',
			searchKey: '',
			dataList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.getDataList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 新增/编辑/查看
		handleEditData(type,data) {
			this.$refs.editData.init(type,data);
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.publishFlag = '';
			this.pageNo = 1;
			this.getDataList();
		},
		// 获取列表数据
		getDataList() {
			let that = this;
			that.$request.post(
				"getTrainOfflineList",
				true,
				{
					searchKey: that.searchKey,
					publishFlag: that.publishFlag,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 更改培训状态
		handleEditDataStatus(dt) {
			let tips = dt.publishFlag == 1 ? '是否撤销当前培训?' : '是否发布当前培训?';
			this.$confirm(tips, {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					dt.publishFlag == 1 ? 'courseTrainOfflineRevoke' : 'publishTrainOffline',
					true,
					{
						id: dt.id,
						publishFlag: dt.publishFlag == 1 ? '0' : '1'
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('培训状态已变更');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前培训?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteTrainOffline',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 关闭
		handleCloseData(dt) {
			this.$confirm('是否关闭当前培训?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'courseTrainOfflineClose',
					true,
					{
						id: dt.id,
						publishFlag: '3'
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('关闭成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'courseTrainOfflineUpload',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getDataList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"courseTrainOfflineDownload",
				true,
				{
					searchKey: that.searchKey,
					publishFlag: that.publishFlag
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/OFFLINE-DATA.xlsx';
			linkNode.download = 'OFFLINE-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
